import {css, html, LitElement, TemplateResult} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';

@customElement('number-ticker')
export class NumberTicker extends LitElement {
    static override styles = [
        css`
            .number-ticker {
                display: flex;
                flex-direction: row-reverse;
                grid-gap: 1px;
                font-family: var(--number-ticker-font-family);
            }

            .number-digit-separator,
            .number-digit,
            .number-currency {
                display: flex;
                font-weight: 700;
            }

            .number-ticker.primary > .number-digit,
            .number-ticker.primary > .number-currency,
            .number-ticker.primary > .number-digit-separator {
                font-size: var(--number-digit-large-font-size);
                height: var(--number-digit-large-height);
                line-height: var(--number-digit-large-height);
                color: var(--number-digit-text-color-primary);
            }

            .number-ticker.secondary > .number-digit-separator,
            .number-ticker.secondary > .number-digit,
            .number-ticker.secondary > .number-currency {
                font-size: var(--number-digit-small-font-size);
                height: var(--number-digit-small-height);
                line-height: var(--number-digit-small-height);
                color: var(--number-digit-text-color-secondary);
            }

            .number-ticker.secondary.content-card > .number-digit-separator,
            .number-ticker.secondary.content-card > .number-digit,
            .number-ticker.secondary.content-card > .number-currency,
            .number-ticker.secondary.content-card > .number-digit-separator,
            .number-ticker.secondary.game-page > .number-digit,
            .number-ticker.secondary.game-page > .number-currency {
                font-size: var(--number-digit-medium-font-size);
                height: var(--number-digit-medium-height);
                line-height: var(--number-digit-medium-height);
            }

            .number-ticker.bar > .number-digit-separator,
            .number-ticker.bar  > .number-digit,
            .number-ticker.bar > .number-currency {
                font-size: var(--number-digit-extra-small-font-size);
                line-height: var(--number-digit-small-bar-height);
                height: var(--number-digit-small-bar-height);
                color: var(--number-digit-text-color-primary);
            }

            .number-ticker.primary > .number-digit,
            .number-ticker.primary > .number-currency {
                border-radius: 4px;
                width: var(--number-digit-large-width);
                justify-content: center;
            }

            .number-ticker.secondary > .number-digit,
            .number-ticker.secondary > .number-currency {
                border-radius: 4px;
                width: var(--number-digit-small-width);
                justify-content: center;
            }

            .number-ticker.secondary.content-card > .number-digit,
            .number-ticker.secondary.content-card > .number-currency,
            .number-ticker.secondary.game-page > .number-digit,
            .number-ticker.secondary.game-page > .number-currency {
                border-radius: 4px;
                width: var(--number-digit-medium-width);
                justify-content: center;
            }

            .number-ticker.bar > .number-digit,
            .number-ticker.bar > .number-currency {
                border-radius: 4px;
                width: var(--number-digit-small-bar-width);
                justify-content: center;
            }

            .number-ticker.primary > .number-digit,
            .number-ticker.bar > .number-digit {
                background-color: var(--number-digit-bg-color-primary);
            }

            .number-ticker.primary > .number-currency,
            .number-ticker.bar > .number-currency {
                background-color: var(--number-currency-bg-color-primary); 
            }

            .number-ticker.secondary > .number-digit {
                background-color: var(--number-digit-bg-color-secondary); 
            }

            .number-ticker.secondary > .number-currency {
                background-color: var(--number-currency-bg-color-secondary);
            }

            .number-ticker.secondary.game-page > .number-currency {
                background-color: var(--number-curruency-bg-color-game-page);
            }

            .number-digit-separator {
                align-items: flex-end;
            }

            .number-ticker.bar > .number-digit-separator {
                color: var(--number-digit-bar-separator);
            }

            @media only screen and (min-width: 576px) {
                .number-ticker.secondary > .number-digit-separator,
                .number-ticker.secondary > .number-digit,
                .number-ticker.secondary > .number-currency {
                    font-size: var(--number-digit-medium-font-size);
                    height: var(--number-digit-medium-height);
                    line-height: var(--number-digit-medium-height);
                }

                .number-ticker.secondary > .number-digit,
                .number-ticker.secondary > .number-currency {
                    width: var(--number-digit-medium-width);
                }
            }

            @media only screen and (min-width: 766px) {
                .number-ticker {
                    justify-content: flex-end !important;
                }
            }

            @media only screen and (min-width: 1024px) {
                .number-ticker.bar > .number-digit-separator,
                .number-ticker.bar  > .number-digit,
                .number-ticker.bar > .number-currency {
                    font-size: var(--number-digit-medium-font-size);
                    line-height: var(--number-digit-medium-height);
                    height: var(--number-digit-medium-height);
                }

                .number-ticker.bar > .number-digit,
                .number-ticker.bar > .number-currency {
                    width: var(--number-digit-medium-width);
                }
            }
        `
    ]

    @property({type: String, attribute: 'currency'})
    currency = 'USD';

    @property({type: Number, attribute: 'value'})
    value = 0;

    @property({type: String, attribute: 'theme'})
    theme = 'primary';

    @property({type: String, attribute: 'location'})
    location = '';

    override connectedCallback(): void {
        super.connectedCallback();
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
    }

    private isFeatureAvailable(): boolean {
        return this.value >= 0;
    }

    private CurrencySymbols: Record<string, string> = {
        'USD': '$',
        'JPY': '¥',
    }

    override render(): TemplateResult<1> {
        if (!this.isFeatureAvailable()) {
            return html``;
        }
        return html`
            <div class="number-ticker ${classMap({
                'primary': this.theme === 'primary',
                'secondary': this.theme === 'secondary',
                'bar': this.theme === 'bar',
                'game-page': this.location === 'userInGamePage',
                'content-card': this.location === 'userInContentCard',
            })}">
                ${String(Math.floor(this.value)).split("").reverse().map((digit, index) => {
                    return html`
                        ${(index) % 3 === 0 && index !== 0 ? html`<span class="number-digit-separator">,</span>` : ''}
                        <span class="number-digit">${digit}</span>
                    `;
                })}
                <span class="number-currency">${this.CurrencySymbols[this.currency]}</span>
            </div>
        `;
    }
}

if (!customElements.get('number-ticker')) {
    customElements.define('number-ticker', NumberTicker);
}
